.scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
}

.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
}

.scrollbar::-webkit-scrollbar {
  width: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #ff5783;
  border-radius: 15px;
}
