@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  background-color: #1f2022;
}

input[type="date"] {
  outline: none;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
