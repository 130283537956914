.chart {
  width: 360px;
  height: 450px;
}

@media (min-width: 1024px) {
  .chart {
    width: 800px;
    height: 630px;
  }
}
